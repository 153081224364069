textarea,
input,
input[type="text"],
input[type="button"],
input[type="submit"] {
    -webkit-appearance: none !important;
    border-radius: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }

    input, textarea, .file-input {
        color: var(--main);
        font-family: var(--font-main);
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(30px, 23px);
        padding-bottom: ac(10px, 8px);
        border-bottom: 1px solid var(--main);
        width: 100%;
        transition: border-color .3s ease;
        background: transparent;

        &::placeholder {
            color: inherit;
            opacity: 1;
            font: inherit;
            transition: color .3s ease;
        }
        &:focus {
            border-color: var(--dark-blue);

           /* &::placeholder {
                color: var(--dark-blue);
            }*/
        }

    }

    .file-input {
        position: relative;
        cursor: pointer;

        input {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        span {
            pointer-events: none;
        }

        &:hover {
            border-color: var(--dark-blue);
        }
    }

    textarea {
        resize: vertical;
        position: relative;
        min-height: ac(40px, 32px);
        height: ac(40px, 32px);
        overflow: hidden;
        &::-webkit-resizer {
            display: none;
        }
    }

    .textarea-resizer {
        position: relative;
        display: flex;

        &:before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 12px;
            background-image: url("../images/icons/resize.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 12px;
            height: 12px;
            z-index: 1;
            pointer-events: none;
        }
    }

    .form-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: ac(52px, 15px);
        grid-row-gap: ac(37px, 22px);

        .textarea {
            grid-column: 1 / -1;
        }

        &:not(:last-child) {
            padding-bottom: ac(30px, 40px);
        }

        @mixin mob {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.default-select {
    height: 60px;
    padding-left: ac(24px, 16px);
    padding-right: ac(24px, 16px);
    border: 1px solid var(--main);
    border-radius: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 124.3%;
    font-family: var(--font-main);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--black);
    transition: border-color .3s ease;
    width: auto;
    background: transparent;

    &:after {
        height: ac(10px, 8px);
        width: ac(10px, 8px);
        top: 48%;
        right: ac(29px, 16px);
        border-color: var(--main);
    }

    &:hover {
        border-color: var(--dark-blue);
    }

    .current {
        font: inherit;
        transition: color .3s ease;
        color: var(--main);
        @mixin max-line-length-one;
        max-width: 88%;
    }

    .list {
        padding: 10px;
        background-color: var(--white);
        border-radius: 0;
        border: none;
        margin-top: ac(12px, 10px);
        top: 100%;
        left: 0;
        z-index: 9;
        width: 100%;
        max-height: 221px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--main) var(--light-grey);
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.1);

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: var(--light-grey);
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--main);
            border-radius: 2px;
        }

        .option {
            @mixin max-line-length-one;
            padding: 0;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            font-family: var(--font-main);
            transition: all .25s ease;
            color: var(--main);

            &.selected {
                font-weight: 500;
                background: transparent;
            }

            &:hover {
                padding-left: 10px;
                background: var(--grey);
            }

            &:first-child {
                display: none;
            }
        }

    }
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .checkbox-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border: 2px solid var(--main);
        border-radius: 1px;
        margin-right: ac(15px, 14px);
        margin-left: 3px;
        transition: border-color .3s ease;

        i {
            font-size: 8.4px;
            color: var(--main);
            opacity: 0;
            transition: opacity .25s ease, color .3s ease;
        }
    }

    input {
        display: none;
    }

    input:checked ~ .checkbox-box {
        border-color: var(--turquoise);

        i {
            color: var(--turquoise);
            opacity: 1;
        }
    }
}

.double-range-container {
    margin-top: ac(4px, 0px);
    width: 100%;
    height: 48px;
}

.double-range {
    padding-right: 15px;
    margin-bottom: 80px;
    height: 2px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: var(--light-grey);

    .noUi-tooltip {
        transform: translate(-50%, 100%);
        left: 50%;
        bottom: -10px;
        display: block !important;
        background: transparent;
        font-size: 15px;
        font-weight: 400;
        font-family: var(--font-main);
        color: var(--main);
        line-height: 1.31;
        padding: 10px 2px;
        border: none;
        &.hidden {
            display: none !important;
        }
    }

    .noUi-connect {
        background: var(--turquoise);
        margin-right: -4px;
    }

    .noUi-handle {
        background: var(--white);
        width: 18px;
        height: 18px;
        border: 2px solid var(--turquoise);
        cursor: pointer;
        border-radius: 50%;
        box-shadow: none;
        top: -8px;

        &:before,
        &:after {
            content: none;
        }
    }
}