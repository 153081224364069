.text-section {
    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .title {
            padding-bottom: ac(20px, 32px);
        }

        .text-content {
            &:not(:last-child) {
                padding-bottom: ac(28px, 32px);
            }
        }
    }

    /*&.with-photo {
        .text-section {
            &__wrap {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                position: relative;

                .info-block {
                    width: 44.06%;
                }

                .photo {
                    @mixin aspect-ratio 466, 500;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
        }
    }*/
}

.text-big-block {
    padding: ac(80px, 30px) ac(80px, 20px);
    width: 100%;
    background: var(--white);
    box-shadow: var(--shadow-main);

    .text-content {
        text-align: center;
    }
}