.job-view {
    background: var(--white);
    padding: ac(40px, 20px);
    margin-bottom: 100px;

    &__wrap {
        display: flex;
        justify-content: space-between;

        @mixin media 991 {
            flex-direction: column-reverse;
        }
    }

    @mixin mob-xl {
        .btn {
            margin: 0 auto;
        }
    }

    .btn-wrap {
        max-width: 226px;
        margin-bottom: 40px;

        .btn {
            width: 100%;
        }

        @mixin mob-xl {
            margin: 0 auto 40px;
        }
    }
}

.job-content__title {
    max-width: 810px;

    @mixin mob-xl {
        text-align: center;
    }
}

.job-content__info {
    padding-top: 40px;
    border-top: 1px solid rgba(25, 19, 89, 0.3);

    &.text-content {
        ul, ol {
            margin-top: 0;
            margin-bottom: 8px;
        }

        li {
            font-size: ac(18px, 16px);
        }

        p {
            line-height: 150%;
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
;
}

.job-content__details {
    margin: 27px 0 0;
    padding-bottom: ac(30px, 20px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;


    &-item {
        color: var(--main);
        display: flex;
        align-items: center;
        margin-bottom: ac(20px, 15px);
        max-width: 100%;
        @mixin max-line-length-one;


        i {
            margin-right: 12px;
        }

        &:not(:last-child) {
            margin-right: 36px;
        }
    }

    @mixin mob-xl {
        justify-content: center;
    }

    &:container(width <= 380px) {
        flex-direction: column;

        .job-content__details-item {
            &:not(:last-child) {
                margin: 0 0 20px;
            }
        }
    }
}

.job-content {
    max-width: 837px;
    width: 69.75%;
}

.job-consultant {
    max-width: 283px;
    width: 25%;

    &__avatar {
        max-width: 283px;
        width: 100%;

        @mixin aspect-ratio 283, 323;

        img {
            transition: transform .3s ease;
        }

        &:hover {
            img {
                transform: scale(1.02);
            }
        }
    }

    &__details {
        margin-top: 16px;
    }

    &__name {
        margin-bottom: 16px;
    }

    @mixin media 991 {
        margin-left: 0;
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        &__avatar {
            width: 100%;
            max-width: 283px;
        }

        &__details {
            margin-top: 0;
            margin-left: 20px;
        }
    }

    @mixin media 480 {
        &__avatar {
            max-width: 100%;
            max-height: 380px;
        }
    }

    @mixin mob-xl {
        flex-direction: column;

        .job-consultant__details {
            margin: 20px 0 0 0;
            text-align: center;
        }

        .job-consultant__socials {
            justify-content: center;
        }
    }
}

.job-consultant__contacts-link {
    font-weight: 400;
    line-height: 120%;
    transition: 0.25s ease;
    display: block;

    &:hover {
        color: var(--blue);
    }

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.job-consultant__socials {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.latest-vacancies {
    padding-bottom: ac(304px, 240px);
    overflow: visible;
    position: relative;

    &__title {
        margin-right: 20px;
    }

    &__wrap:container(width <= 445px) {
        flex-direction: column;
        .latest-vacancies__title {
            margin: 0 0 20px;
        }
    }

    /*.latest-vacancies-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: ac(500px, 300px);
        background: linear-gradient(134.89deg, #25E1EF 0%, #3024ED 100.2%);
        overflow: hidden;

        .lottie-animation {
            bottom: -2%;
            left: -40%;
            opacity: .22;
            position: absolute;
            transform: rotate(-35deg) scaleX(1.4);
            width: 120%;
        }
    }*/

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: ac(500px, 300px);
        background: url("../images/decors/blue-bottom-bg.svg") no-repeat center / cover;
    }
}
