.swiper-pagination {
    position: static;
    max-width: 184px;
    margin: 43px auto 2px;
    height: 4px;

    &:not(.our-events-pagination) {
        display: none;
    }

    &.swiper-pagination-blue {
        --swiper-theme-color: var(--gradient-main);

        &.swiper-scrollbar-horizontal {
            background: var(--light-grey);
        }

        .swiper-scrollbar-drag {
            background: var(--gradient-main);
        }
    }

    &.swiper-pagination-white {
        --swiper-theme-color: var(--white);

        &.swiper-scrollbar-horizontal {
            background: rgba(239, 239, 239, 0.46);
        }

        .swiper-scrollbar-drag {
            background: var(--white);
        }
    }

    .swiper-scrollbar-drag {
        border-radius: 0;
        height: 8px;
        top: -2px;
    }

    @mixin mob-xl{
        &:not(.our-events-pagination) {
            display: block;
        }
    }
}