.first-interior-section {
  &.blogs-page {
      &.jobs-page {
          padding-top: ac(210px, 190px);
          padding-bottom: ac(122px, 80px);
          .title {
              width: 100%;
              text-align: center;
              max-width: ac(940px, 600px);
              margin: 0 auto;
          }
      }
  }
}

.jobs-search-section {
    padding-top: ac(48px, 40px);
    padding-bottom: 0;
    overflow: visible;
    position: relative;
    z-index: 2;
    margin-bottom: ac(-66px, -50px);

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: ac(66px, 50px);
        background: var(--white);
    }

    &__wrap {
        display: flex;
        justify-content: space-between;

        @mixin tab-sm {
            position: relative;
            width: 100%;
        }
    }

    .sidebar {
        width: 30%;
        max-width: 305px;
        flex-shrink: 0;
        &__buffer {
            display: block;
        }

        &__inner {
            position: sticky;
            padding-bottom: 60px;
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-bottom: ac(30px, 20px);
            width: 100%;

            &:not(:first-child) {
                .sidebar {
                    &__item-top {
                        margin-top: ac(30px, 20px);
                    }
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--main);
            }

            .double-range-container {
                max-width: 283px;
            }
        }

        &__item-top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;

            .accordion-icon {
                position: absolute;
                font-size: 8px;
                top: 50%;
                transform: translateY(-50%);
                color: var(--main);
                right: 8px;
                transition: transform .25s ease;
            }

            &.accordion-toggle {
                cursor: pointer;

                h3 {
                    pointer-events: none;
                }

                &.active {
                    .accordion-icon {
                        transform: scale(1, -1) translateY(25%);
                    }
                }
            }
        }

        &__item-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: ac(30px, 20px);
            width: 100%;

            transition: max-height .25s ease, margin-top .3s ease;

            &.accordion-content {
                margin-top: 0;
                overflow: hidden;
                max-height: 0;
                z-index: 3;

                &.active {
                    overflow: visible;
                    margin-top: ac(30px, 20px);
                    max-height: 300px;
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;

                max-height: calc(((ac(20px, 16px) * 1.32) + ac(21px, 16px)) * 6.02);

                .simplebar-track.simplebar-vertical {
                    width: 4px;
                    background: var(--light-grey);
                    border-radius: 2px;
                    transform: translateX(8px);


                    .simplebar-scrollbar {
                        background: var(--main);
                        border-radius: 2px;

                        &:before {
                            content: none;
                        }
                    }
                }

                li {

                    p {
                        font-size: ac(20px, 16px);
                        font-weight: 400;
                        line-height: 131.5%;
                    }

                    &:not(:last-child) {
                        margin-bottom: ac(21px, 16px);
                    }
                }
            }
        }

        @mixin tab-sm {
            position: absolute;
            width: 300px;
            top: 0;
            left: 0;
            z-index: 1;
            max-width: 300px;
            transition: transform .3s ease;
            transform: translateX(-120%);

            &.active {
                transform: translateX(0);
            }

            .sidebar {
                &__buffer {
                    display: none;
                }

                &__inner {
                    position: static;
                }
            }

            @mixin mob {
                width: 270px;
                max-width: 270px;
            }

            @mixin mob-sm {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    .jobs-list {
        width: 66.17%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        z-index: 1;

        &__top {
            padding-bottom: 30px;
            width: 100%;
        }

        .showing-results {
            font-size: ac(18px, 16px);
            font-weight: 400;
            line-height: 144.3%;
            color: var(--main);
        }

        .job-card {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        @mixin tab-sm {
            width: 100%;
            transition: transform .3s ease;
            transform: translateX(0);

            &:before {
                position: absolute;
                content: "";
                left: -20px;
                bottom: 0;
                right: 0;
                height: 70%;
                background: linear-gradient(135deg, color-mod(var(--white) a(0%)) 0%, var(--white) 50%, var(--white) 100%);
                opacity: 0;
                pointer-events: none;
                z-index: 1;
                transition: opacity .3s ease;
            }

            .job-card {
                position: relative;

                &:before {
                    position: absolute;
                    content: "";
                    inset: 0;
                    background: linear-gradient(90deg, color-mod(var(--white) a(10%)) 0%, var(--white) 100%);
                    opacity: 0;
                    pointer-events: none;
                    z-index: 1;
                    transition: opacity .3s ease;

                    @mixin mob-lg {
                        background: linear-gradient(90deg, color-mod(var(--white) a(10%)) 0%, var(--white) 50%, var(--white) 100%);
                    }
                }
            }



            &.active {
                transform: translateX(330px);

                &:before {
                    opacity: 1;
                }

                .job-card {
                    pointer-events: none;
                    cursor: initial;
                    &:before {
                        pointer-events: auto;
                        opacity: 1;
                    }
                }

                @mixin mob {
                    transform: translateX(290px);
                }

                @mixin mob-sm {
                    transform: translateX(330px);
                }
            }
        }


    }

    &__wrap {
        @mixin tab-sm {
            &.active {
                clip-path: inset(0 -50px 50px -50px);
                .jobs-list {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .sidebar {
                    position: static;
                }
            }
        }

    }
}

.result-section {
    &.jobs-page {
        padding-top: ac(146px, 120px);
        padding-bottom: ac(304px, 190px);

        .result-section {
            &__wrap {
                padding-left: 33.83%;

                @mixin tab-sm {
                    padding-left: 0;
                }
            }
        }


        .result-section-bg {
            top: 0;

            .lottie-animation {
                bottom: -2%;
                left: -40%;
                opacity: .22;
                position: absolute;
                transform: rotate(-35deg) scaleX(1.4);
                width: 120%;
            }


            .decor-lines {
                @mixin aspect-ratio 1756, 1282;
                position: absolute;
                top: ac(-184px, -40px);
                right: 15.90vw;
                width: 121.94%;

                img {
                    object-fit: contain;
                }
            }
        }

        @mixin tab-sm {
            transition: padding-top .25s ease;

            &.active {
                padding-top: 30px;

                .pagination {
                    display: none;
                }
            }
        }
    }
}