/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  transition: background ease-in-out 0.25s, height ease-in-out 0.25s;
  background: var(--white);
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
/*  height: ac(100px, 81px);*/
  padding: ac(13px, 16px) 0;
  display: flex;
  align-items: center;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &.scrolled {
    background: rgba(var(--black-rgb), 0.8);
    backdrop-filter: blur(10px);
    /*height: ac(100px, 60px);*/

    .logo {
      width: ac(160px, 100px);
    }
  }

  .logo {
    height: 100%;
    width: ac(180px, 120px);
    flex-shrink: 0;
    will-change: transform;
    transition: transform .25s ease;
    position: relative;
    z-index: 1;

    &::before {
      display: none;
    }

    &:hover {
      transform: scale(1.06);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      path {
        transition: all .3s ease;
      }
    }

    stop {
      transition: all .3s ease;
    }

    &.active {
      svg {
        path {
          &:not(.logo-path) {
            fill: var(--white);
          }
        }

        stop {
          stop-color: var(--white);
        }
      }
    }
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-grow: 1;

    .decor-lines {
      display: none;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .menu-item {
    position: relative;

    &:after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: var(--main);
    }

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: "\e999";
          font-family: "icomoon";
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }

    &.only-phone {
      display: none;

      @mixin mob-xl {
        display: flex;
        order: 10;
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @media (--mobile-menu-end-point) {
        padding: 5px 0;
        transform: translateX(-25px);

        &:not(:last-child) {
          margin-right: 24px;
          padding-right: 28px;

          &:after {
            content: "";
          }
        }
      }

      &:hover {
        @media (--mobile-menu-end-point) {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: ac(16px, 14px);
    line-height: 175.00%;
    font-weight: 500;
    @mixin transition-all;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--main);
      @mixin transition-all;
    }

    &:hover {

      &::before {
        width: 100%;
        left: 0;
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--bg-second);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }

  .btn {
    box-shadow: 0 0 0 ac(1.5px, 1px) var(--turquoise);
    font-size: 14px;
    padding: 8px 29px;

    &:after {
      background-color: var(--turquoise);
    }

    &:before {
      background-color: var(--turquoise);
    }

    &:hover {
      box-shadow: 0 0 0 ac(1.5px, 1px) var(--main);
      &:after {
        background-color: var(--main);
      }

      &:before {
        background-color: var(--main);
      }
    }

    @media (--mobile-menu-start-point) {
      height: 38px;
      margin-right: 20px;
      margin-left: auto;
      padding: 7px 29px;

      &.active {
        color: var(--white);
      }
    }

    @mixin mob-xl {
      display: none;
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      min-width: 320px;
      padding-top: ac(160px, 103px);
      background: var(--gradient-main);
      padding-right: 5px;
      padding-bottom: 40px;
      transition: transform 0.3s ease-out;
      clip-path: inset(0 0 0 0);
      overflow: hidden;

      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }

      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      .menu {
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 20px;
        align-items: flex-start;
        /*align-self: center;*/
        position: relative;
        z-index: 1;

        &::-webkit-scrollbar {
          width: 3px;
          height: 2px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.5);
          border-radius: 3px;
          cursor: pointer;
        }
      }

      .menu-item {
        position: relative;
        padding: 15px 0 15px 26px;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: var(--white);
        }

      }

      .menu-link {
        font-size: ac(52px, 30px);
        font-weight: 600;
        line-height: 126.67%;
        color: var(--white);

        &:before {
          background: var(--white);
        }
      }

      /*@mixin mob-xl {
        width: 80%;
      }

      @mixin mob-lg {
        width: 100%;
      }*/

      .decor-lines {
        display: block;
        @mixin aspect-ratio 1604, 991;
        position: absolute;
        bottom: -940px;
        left: 50%;
        transform: translateX(-50%) translateY(-377px);
        width: 1604px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 45%;
        right: 31px;
        background-image: url("../images/decors/bg-logo-menu.svg");
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(-50%);
        width: ac(380px, 160px);
        height: ac(380px, 160px);
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }
}

/* end of Common desktop header Mobile HEADER */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.header.header-mobile {
  .burger {
    display: flex;
  }

  .navbar-nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;
    height: 100vh;
    min-width: 320px;
    padding-top: 100px;
    background: var(--bg-second);
    padding-right: 5px;
    padding-bottom: 40px;
    transition: transform 0.3s ease-out;

    &.nav-slide-left {
      right: auto;
      left: 0;
      transform: translateX(-100%);

      &.active {
        transform: translateX(0);
      }
    }

    &.nav-slide-right {
      right: 0;
      left: auto;
      transform: translateX(100%);

      &.active {
        transform: translateX(0);
      }
    }

    &.nav-slide-down {
      right: auto;
      left: 0;
      transform: translateY(-100%);

      &.active {
        transform: translateY(0);
      }
    }

    &.nav-slide-up {
      right: auto;
      left: 0;
      transform: translateY(100%);

      &.active {
        transform: translateY(0);
      }
    }

    .menu {
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 40px;
      align-items: flex-start;
      align-self: center;

      &::-webkit-scrollbar {
        width: 3px;
        height: 2px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 3px;
        cursor: pointer;
      }
    }

    .menu-link {
      padding: 10px 0;
      font-size: ac(25px, 18px);
    }
  }

  .dropdown-menu {
    position: relative;
    top: 0;
  }
}

/* end of ONLY Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  border: 1px solid var(--turquoise);
  padding: 10px;
  transition: border-color .25s ease;

  span {
    width: 12px;
    height: 2px;
    background: var(--main);
    transition: all 0.1s;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      width: calc(100% + 6px);
      height: 2px;
      background: var(--main);

      transition: all 0.25s;
    }

    &::before {
      top: -8px;
    }

    &::after {
      bottom: -8px;
    }
  }

  @mixin transition-all;

  @mixin min-media 1025 {
    &:hover {
      transform: scale(1.1);
    }
  }

  &.active {
    height: 40px;
    border-color: var(--white);

    span {
      background: transparent;
      width: 18px;

      &::before {
        top: 0;
        transform: rotate(-45deg);
        width: 100%;
        background: var(--white);
      }

      &::after {
        top: 0;
        transform: rotate(45deg);
        width: 100%;
        background: var(--white);
      }
    }

    &:hover {
      height: 40px;
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  @mixin transition-all;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

/* end of HEADER COMPONENTS */
