.first-interior-section {
    &.about-us-page {
        background: var(--gradient-main-about);
        padding-top: ac(208px, 140px);
        padding-bottom: ac(122px, 70px);

        .lottie-animation {
            bottom: -1%;
            opacity: .34;
            position: absolute;
            right: -10%;
            width: 120%;

            &:before {
                inset: 0;
                position: absolute;
                content: "";
                background: linear-gradient(106.34deg, color-mod(#25E1EF a(0%)) 0%, rgba(48,51,239,1) 100%);
                z-index: 1;
            }

            @mixin mob-xl {
                opacity: .5;
                &:before {
                    content: none;
                }
            }
        }


        .decor-lines {
            @mixin aspect-ratio 1567, 1193;
            position: absolute;
            right: 11.74%;
            top: ac(100px, 80px);
            width: 110.01%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: right;
            }
        }
        .title {
            width: 47.89%;
        }

        .text-content {
            padding-top: ac(30px, 20px);
            width: 47.89%;

            p, a {
                line-height: 162.5%;
                font-weight: 500;
            }
        }

        .logo-decor {
            @mixin aspect-ratio 234, 231;
            position: absolute;
            right: ac(80px, 0px);
            top: 50%;
            transform: translateY(-48%);
            width: ac(234px, 140px);
            opacity: .3;
            z-index: 1;
            pointer-events: none;

            img {
                object-fit: contain;
                transform: scale(0.99);
            }
        }

        @mixin tab-md {
            .title {
                width: 100%;
                max-width: 400px;
            }
            .text-content {
                width: 100%;
                max-width: 400px;
            }
        }

        @mixin mob-xl {

            .logo-decor {
                opacity: .2;
            }

        }

    }
}

.text-section {
    &.first-about-info {
        padding-top: ac(98px, 70px);
        background: var(--white);
        padding-bottom: ac(100px, 70px);

        .text-section{
            &__wrap {
                position: relative;
            }
        }

        .text-content {
            width: 52.58%;

            &:not(:last-child) {
                padding-bottom: ac(60px, 30px);
            }
        }

        .photo {
            &.photo-bottom {
                @mixin aspect-ratio 566, 320;
                width: 46.74%;
                max-width: 566px;

                img {
                    transition: transform .3s ease;
                }

                &:hover {
                    img {
                        transform: scale(1.02);
                    }
                }

                &.new-img {
                    @mixin aspect-ratio 1080, 909;

                    img {
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }

            &.photo-right {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 39.61%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                img {
                    transition: transform .3s ease;
                }

                &:hover {
                    img {
                        transform: scale(1.02);
                    }
                }

                &.new-img {
                    @mixin aspect-ratio 633, 548;
                    position: absolute;

                    img {
                        object-fit: contain;
                        object-position: center;
                    }

                    @mixin tab-md {
                        width: 42%;
                    }
                }

                @mixin tab-md {
                    width: 42%;
                }
            }
        }

        @mixin mob-xl {
            .text-content {
                width: 100%;
            }

            .photo {
                &.photo-bottom {
                    display: none;
                }
                &.photo-right {
                    width: 100% !important;
                    position: relative !important;
                }
            }
        }

    }
}

.text-section {
    &.community-sec {
        padding-top: ac(100px, 70px);
        padding-bottom: ac(87px, 35px);
        .text-section__wrap {
            padding-top: ac(50px, 40px);
            padding-bottom: ac(189px, 80px);
            position: relative;
            padding-left: 50.47%;

            &:before {
                content: "";
                position: absolute;
                right: ac(76px, 0px);
                bottom: ac(-80px, -30px);
                width: ac(275px, 137px);
                height: ac(273px, 136px);
                background-image: url("../images/decors/bg-logo.svg");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                pointer-events: none;
            }

            .title {
                padding-bottom: ac(30px, 20px);
            }

            .text-content {
                &:not(:last-child) {
                    padding-bottom: ac(32px, 30px);
                }
            }

            @mixin tab {
                padding-bottom: ac(106px, 60px);
                &:before {
                    opacity: .15;
                    bottom: 100px;
                    right: 0;
                }
            }

            @mixin mob-xl {
                padding-bottom: 0;
                padding-top: 0;
                padding-left: 0;

            }
        }

        .photo {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 47.34%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: left;
            }

            img {
                transition: transform .3s ease;
            }

            &:hover {
                img {
                    transform: scale(1.02);
                }
            }

            &.new-img {
                @mixin aspect-ratio 1080, 909;
                position: absolute;

                img {
                    object-fit: contain;
                    object-position: center;
                }
            }
        }

        .btn {
            padding: 10px ac(44px, 20px);
        }

        @mixin mob-xl {

            .photo {
                @mixin aspect-ratio 606, 659;
                margin-bottom: 20px;
                width: 100%;

                img {
                    object-fit: contain;
                    object-position: center;
                }

                &.new-img {
                    position: relative;

                    img {
                        object-fit: contain;
                        object-position: center;
                    }
                }

            }
        }
    }

    + .talk-section.interior-pages {
        margin-top: ac(80px, 60px);
    }
}

.our-events-section {
    padding-top: ac(48px, 35px);
    padding-bottom: ac(100px, 70px);
    position: relative;
    &:before {
        left: 0;
        right: 0;
        bottom: 0;
        height: ac(549px, 300px);
        position: absolute;
        content: "";
        background: var(--white);
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        z-index: 1;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: ac(42px, 30px);

        .btn {
            transform: translateY(ac(2px, 0px));
            padding: 10px ac(44px, 20px);
        }
    }

    .our-events-slider {
        width: 100%;
        max-width: 100%;
        overflow: visible;

        .swiper-wrapper {
            padding-bottom: 25px;
        }

        .swiper-slide {
            height: auto;
        }

        .our-events-pagination {
            max-width: 49.07%;

            @mixin mob-lg {
                max-width: 184px;
            }
        }
    }

    + .talk-section.interior-pages {
        margin-top: ac(80px, 60px);
    }
}

.our-core-values-section {
    padding-top: ac(96px, 70px);
    background: var(--gradient-second-about);
    padding-bottom: ac(344px, 220px);
    overflow: hidden;

    .lottie-animation {
        &--top {
            opacity: .20;
            position: absolute;
            right: -10%;
            top: -10%;
            width: 135%;
            transform: scaleX(1.6) rotate(-10deg);
        }

        &--bottom {
            bottom: -1%;
            opacity: .24;
            position: absolute;
            right: -10%;
            width: 120%;
        }

        /*&:before {
            inset: 0;
            position: absolute;
            content: "";
            background: linear-gradient(106.34deg, color-mod(#3033EF a(0%)) 0%, rgba(48,51,239,1) 100%);
            z-index: 1;
        }*/
    }

    .decor-lines {
        @mixin aspect-ratio 2614, 2215;
        position: absolute;
        left: 48%;
        transform: translateX(-50%);
        top: ac(25px, 15px);
        width: 176.75%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: right;
        }
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        z-index: 1;
    }

    .title {
        h2 {
            color: var(--white);
        }
    }

    .core-values-list {
        position: relative;
        z-index: 1;
        padding-top: ac(100px, 70px);
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: ac(20px, 16px);
        grid-row-gap: ac(76px, 60px);
        max-width: 1064px;
        margin: 0 auto;

        @mixin mob-xl {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}