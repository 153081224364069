.first-interior-section {
    &.events-page {
        padding-top: ac(254px, 190px);
        padding-bottom: ac(211px, 120px);

        /*.lottie-animation {
            bottom: -15%;
            opacity: .22;
            position: absolute;
            left: -60%;
            width: 140%;
            transform: rotate(-35deg) scaleX(1.2);
        }*/

        .lottie-animation {
            bottom: -1%;
            opacity: .34;
            position: absolute;
            right: -10%;
            width: 120%;

            &:before {
                inset: 0;
                position: absolute;
                content: "";
                background: linear-gradient(134.89deg, color-mod(#25E1EF a(0%)) 0%, rgba(48,51,239,1) 100%);
                z-index: 1;
            }

            @mixin mob-xl {
                opacity: .5;
                &:before {
                    content: none;
                }
            }
        }

        .decor-lines {
            @mixin aspect-ratio 1829, 1130;
            position: absolute;
            right: 115px;
            top: 115px;
            width: 127.01%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: right;
            }
        }
        .title {
            width: 63%;

            h1 {
                letter-spacing: -0.5px;
            }
        }

        .logo-decor {
            @mixin aspect-ratio 380, 373;
            position: absolute;
            right: 18px;
            top: 50%;
            transform: translateY(-50%);
            width: ac(380px, 140px);
        }

        @mixin mob-xl {

            .title {
                max-width: 370px;
                width: 100%;
            }

            .logo-decor {
                right: 0;
                opacity: .5;
            }

        }

    }
}

.result-section {
    &.events-page {
        padding-top: ac(80px, 60px);
        /*padding-bottom: ac(410px, 210px);*/
        padding-bottom: ac(140px, 90px);

        .result-section-bg {
            /*height: ac(755px, 400px);*/
            height: ac(500px, 350px);

            .lottie-animation {
                bottom: -2%;
                left: -50%;
                opacity: .22;
                position: absolute;
                transform: rotate(-35deg) scaleX(1.4);
                width: 120%;
            }

            .cont {
                &.for-decor {
                    position: absolute;
                    left: 50%;
                    top: 0;
                    bottom: 0;
                    transform: translateX(-50%);

                    &:before {
                        content: "";
                        position: absolute;
                        right: 29px;
                        /*top: 50%;*/
                        background-image: url("../images/decors/bg-logo.svg");
                        opacity: .2;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        width: ac(186px, 124px);
                        height: ac(183px, 122px);
                        /*transform: translateY(-82%);*/
                        bottom: 50px;
                    }

                    @mixin mob-lg {
                        display: none;
                    }
                }
            }



            .decor-lines {
                @mixin aspect-ratio 1756, 1282;
                position: absolute;
                top: ac(68px, 40px);
                right: 15.90vw;
                width: 121.94%;

                img {
                    object-fit: contain;
                }
            }
        }
    }
}

.events-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(45px, 40px);
    padding-bottom: ac(108px, 80px);

    @mixin tab {
        grid-column-gap: 10px;
    }

    @mixin tab-md {
        grid-template-columns: repeat(2, 1fr);
    }

    @mixin mob-lg {
        grid-template-columns: repeat(1, 1fr);
    }
}

.text-big-section {
    padding-bottom: ac(0px, 30px);
    &.events-page {
        position: relative;
        z-index: 3;
        margin-top: ac(-290px, -100px);
    }
}