:root {
  --black: #161626;
  --bg-second: #343434;
  --main: #1B105D;
  --blue: #1984EE;
  --dark-blue: #3514F7;
  --light-grey: #EFEFEF;
  --grey: #F1F1F1;
  --turquoise: #25E1EF;
  --light-turquoise: #26E1EF;
  --white: #ffffff;
  --whitesmoke: #f5f5f5;
  --primary: #ffde33;
  --secondary: #088ded;

  --gradient-main: linear-gradient(134.89deg, rgba(37,225,239,1) 0%, rgba(48,36,237,1) 100%);
  --gradient-main-about: linear-gradient(106.34deg, #27D5EF -5.68%, #3033EF 102.49%);
  --gradient-second-about: linear-gradient(132.81deg, #3024ED -2.06%, #25E1EF 110.32%);
  --gradient-category: linear-gradient(100.7deg, #26DAEF -38.91%, #1881EE 92.54%);

  --shadow-main: 0px 0px 60px rgba(0, 0, 0, 0.1);

  --font-main: "Gilroy", sans-serif;
  --font-second: "Roobert", sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1200px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1201px);
