.search-section {
    overflow: visible;
    margin-top: ac(-36px, -27px);
    position: relative;
    z-index: 2;

    &.jobs-page {
        z-index: 4;
        margin-top: ac(-30px, -27px);
        &:before {
            content: "";
            z-index: -1;
            inset: 0;
            position: absolute;
            background: var(--white);
            transform: translateY(ac(30px, 27px));
        }
    }
}
.search-form {
    width: 100%;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    &.events-page {
        grid-template-columns: 1fr ac(253px, 180px) 140px;

        @mixin tab-sm {
            grid-template-columns: 1fr ac(140px, 120px);

            input {
                &.full-grid-col {
                    grid-column: 1 / -1;
                }
            }
        }

        @mixin mob-sm {
            grid-template-columns: 1fr;
        }
    }

    &.blogs-page {
        grid-template-columns: 1fr ac(253px, 180px) ac(253px, 180px) 140px;

        @mixin tab-md {
            grid-template-columns: 1fr 1fr ac(140px, 120px);

            input {
                &.full-grid-col {
                    grid-column: 1 / -1;
                }
            }
        }

        @mixin mob-xl {
            grid-column-gap: 16px;
            grid-row-gap: 16px;
        }

        @mixin mob-lg {
            grid-template-columns: 1fr;
        }
    }

    &.jobs-page {
        grid-template-columns: 1fr ac(381px, 180px) 140px;
        position: relative;

        .btn-filters {
            display: none;
        }

        @mixin tab-sm {
            grid-template-columns: 1fr 1fr 1fr;

            .full-grid-col {
                grid-column: 1 / -1;
            }

            .btn {
                clip-path: none;
            }

            .btn-filters {
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 12px;
                    margin-right: 10px;
                    color: var(--main);
                    transition: color .25s ease;
                }

                &:hover {
                    i {
                        color: var(--white);
                    }
                }

                &.active {
                    i {
                        &:before {
                            content: "\e90c";
                        }
                    }

                    color: var(--white);
                    box-shadow: 0 0 0 ac(1.5px, 1px) var(--main);

                    &:before {
                        background-color: var(--main);
                        transform: translateX(0);
                    }

                    &:after {
                        background-color: var(--main);
                        transform: translateX(0);
                    }

                    i {
                        color: var(--white);
                    }
                }
            }
        }

        @mixin mob-lg {
            grid-template-columns: 1fr 1fr;
        }

        @mixin mob-sm {
            grid-template-columns: 1fr 1fr;
        }
    }

    @mixin mob-lg {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }

    input {
        height: ac(60px, 50px);
        color: var(--main);
        font-family: var(--font-main);
        font-size: 16px;
        font-weight: 500;
        line-height: 124%;
        padding: ac(16px, 8px) ac(20px, 16px);
        border: 1px solid var(--main);
        width: 100%;
        transition: border-color .3s ease;
        background: var(--white);

        &::placeholder {
            color: inherit;
            opacity: .5;
            font: inherit;
            transition: color .3s ease;
        }
        &:focus {
            border-color: var(--dark-blue);

            /* &::placeholder {
                 color: var(--dark-blue);
             }*/
        }
    }

    .btn {
        line-height: 1;
        height: ac(60px, 50px);
        clip-path: inset(0 0 0 0);
        padding: ac(10px, 6px) 16px;
        width: 100%;
    }

    .default-select {
        height: ac(60px, 50px);
        background: var(--white);
    }
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__item {
        padding: 0 ac(12px, 6px);
    }

    &__prev {
        margin-right: ac(32px, 12px);
    }

    &__next {
        margin-left: ac(32px, 12px);
    }

    &__num {
        font-size: ac(24px, 20px);
        font-weight: 600;
        line-height: 144%;
        cursor: pointer;
        opacity: 0.5;
        transition: opacity .25s ease;
        color: var(--white);
        position: relative;

        &:before {
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
            height: 2px;
            background: var(--white);
            width: 0;
            transition: width .25s ease;
        }

        &.active {
            opacity: 1;
            cursor: not-allowed;

            &:before {
                content: none;
            }
        }

        &:hover {
            opacity: 1;

            &:before {
                right: auto;
                left: 0;
                width: 100%;
            }
        }
    }

    .btn {
        min-width: ac(157px, 140px);
        font-size: 16px;
        padding: 10px ac(26px, 16px);
        opacity: 0.5;
        cursor: not-allowed;

        @mixin tab {
            min-width: auto;
        }

        @mixin mob-lg {
            span {
                display: none;
            }
        }

        &:before, &:after {
            opacity: 0;
        }

        &:hover {
            box-shadow: 0 0 0 ac(1.5px, 1px) var(--white);
        }

        &.active {
            opacity: 1;
            cursor: pointer;

            &:before, &:after {
                opacity: 1;
            }

            &:hover {
                box-shadow: 0 0 0 ac(1.5px, 1px) var(--main);

                &:before {
                    background-color: var(--main);
                }

                &:after {
                    background-color: var(--main);
                }
            }
        }
    }
}

.result-section {
    position: relative;
    z-index: 1;
    .result-section-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        clip-path: inset(0 0 0 0);
        background: var(--gradient-main);
        z-index: -1;

        .decor-lines {
            position: absolute;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}