.footer {
  padding-top: ac(98px, 28px);
  background: var(--light-grey);
  padding-bottom: ac(98px, 40px);

  &__top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: ac(10px, 30px);

    @mixin tab-md {
      flex-direction: column;
    }
  }

  &__links {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    ul {
      &:not(:last-child) {
        margin-right: ac(68px, 30px);
        margin-bottom: ac(12px, 8px);
      }

      li {

        a {
          font-family: var(--font-main);
          font-weight: 600;
          line-height: ac(28px, 23px);
          transition: color .3s ease;

          &:hover {
            color: var(--dark-blue);
          }
        }

        &:not(:last-child) {
          padding-bottom: ac(0px, 4px);
        }
      }
    }

    @mixin tab-md {
      flex-direction: row;

      ul{
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    @mixin mob-lg {
      flex-direction: column;

      ul {
        &:not(:last-child) {
          margin-right: 0;
          padding-bottom: 12px;
        }

        li {
          a {
            br {
              display: none;
            }
          }
        }
      }
    }
  }

  &__awards {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -9.5%;

    img {
      height: ac(130px, 80px);
      margin: 0 8px;
      object-fit: contain;
    }

    @mixin tab-md {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  &__nav {
    padding-right: ac(59px, 0px);

    ul {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: ac(43px, 18px);
      grid-row-gap: ac(6px, 20px);

      li {
        /*padding-bottom: ac(16px, 20px);*/

        a {
          font-family: var(--font-main);
          font-weight: 400;
          line-height: ac(30px, 23px);
          transition: color .3s ease;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            height: 2px;
            background: var(--main);
            width: 0;
            transition: width .25s ease;
          }

          &:hover {
            color: var(--dark-blue);

            &:before {
              width: 100%;
              right: auto;
              left: 0;
              background: var(--dark-blue);
            }
          }
        }
      }

    }

    @mixin tab-md {
      margin-left: 0;
      padding-top: 20px;

      ul {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    @mixin mob-lg {
      ul {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &__bottom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @mixin mob-xl {
      padding-bottom: 40px;
      flex-wrap: wrap;
    }
  }

  &__bottom-logo {
    width: ac(42px, 36px);
    height: ac(42px, 36px);
    transition: transform .25s ease;
    cursor: pointer;
    margin-right: ac(20px, 12px);

    img {
      width: 100%;
      object-fit: contain;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  &__bottom-info {
    p {
      font-size: 14px;
      font-family: var(--font-main);
      font-weight: 400;
      line-height: ac(28px, 20px);
    }

    a {
      font-size: 14px;
      font-family: var(--font-main);
      font-weight: 400;
      line-height: ac(28px, 20px);
      transition: color .25s ease;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        height: 2px;
        background: var(--main);
        width: 0;
        transition: width .25s ease;
      }

      &:hover {
        color: var(--dark-blue);

        &:before {
          width: 100%;
          right: auto;
          left: 0;
          background: var(--dark-blue);
        }
      }
    }

    &--privacy {
      margin-left: auto;
      margin-right: 14.85%;

      @mixin min-media 951 {
        a {
          font-size: ac(18px, 16px);
          font-family: var(--font-main);
          font-weight: 400;
          line-height: ac(30px, 23px);
        }
      }

      @mixin media 950 {
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }

  .bold-credits {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: ac(150px, 120px);
    transition: transform .25s ease;
    will-change: transform;
    transform-origin: left;

    &:hover {
      transform: scale(1.03) translateX(-50%) translateY(-50%);
    }

    @mixin tab-lg {
      left: auto;
      right: 0;

      transform: translateX(0) translateY(-50%);

      &:hover {
        transform: scale(1.03) translateX(0) translateY(-50%);
      }
    }

    @mixin mob-xl {
      right: auto;
      left: 0;
      bottom: 0;
      top: auto;

      transform: translateX(0) translateY(0);

      &:hover {
        transform: scale(1.03) translateX(0) translateY(0);
      }
    }
  }
}