.btn {
    font-family: var(--font-main);
    color: var(--main);
    background: transparent;
    font-size: 16px;
    line-height: 175.00%;
    padding: 10px 44px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 0 0 ac(1.5px, 1px) var(--main);
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    transition: color .3s ease, background-color .4s ease, box-shadow .25s ease, opacity .25s;
    width: max-content;
    white-space: nowrap;

    &:before,
    &:after {
        position: absolute;
        inset: -1px;
        background: var(--main);
        content: "";
        z-index: -1;
        transition: transform .3s ease, background-color .3s ease;
    }

    &:before {
        transform: translateX(-101%);
        clip-path: polygon(0% 0%,100% 0%,70% 100%,0% 100%);
    }

    &:after {
        transform: translateX(101%);
        clip-path: polygon(30% 0, 100% 0px, 100% 100%, 0% 100%);
    }

    &:hover {
        color: var(--white);

        &:before {
            transform: translateX(0);
        }

        &:after {
            transform: translateX(0);
        }
    }

    &-fill-white {
        background: var(--white);
        box-shadow: 0 0 0 ac(1.5px, 1px) var(--white);
        color: var(--main);

        /*&:before {
            background-color: var(--white);
        }

        &:after {
            background-color: var(--white);
        }*/

        &:hover {
            box-shadow: 0 0 0 ac(1.5px, 1px) var(--main);
            color: var(--white);

            &:before {
                background-color: var(--main);
            }

            &:after {
                background-color: var(--main);
            }
        }
    }

    &-white {
        box-shadow: 0 0 0 ac(1.5px, 1px) var(--white);
        color: var(--white);

        /*&:before {
            background-color: var(--white);
        }

        &:after {
            background-color: var(--white);
        }*/

        &:hover {
            box-shadow: 0 0 0 ac(1.5px, 1px) var(--main);

            &:before {
                background-color: var(--main);
            }

            &:after {
                background-color: var(--main);
            }
        }
    }

    &-turquoise {
        box-shadow: 0 0 0 ac(1.5px, 1px) var(--turquoise);
        background: var(--turquoise);

        /*&:before {
            background-color: var(--turquoise);
        }

        &:after {
            background-color: var(--turquoise);
        }*/

        &:hover {
            box-shadow: 0 0 0 ac(1.5px, 1px) var(--main);

            &:before {
                background-color: var(--main);
            }

            &:after {
                background-color: var(--main);
            }
        }
    }
}

.btn-more {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    border: 1px solid color-mod(var(--main) a(30%));
    width: 40px;
    height: 40px;
    color: var(--main);
    background: var(--white);
    transition: background-color .25s ease, color .3s ease;
    cursor: pointer;

    &:hover {
        background: var(--main);
        color: var(--white);
    }

    i {
        transition: transform .3s ease, color .3s ease;
    }

    &.active {
        i {
            transform: scale(-1, -1);
        }
    }

    &.more-hidden {
        display: none;
    }
}