html {
  font-size: 16px;

  @mixin media 1450 {
    font-size: 14px;
  }

  @mixin media 1025 {
    font-size: 12px;
  }

  @mixin media 551 {
    font-size: 10px;
  }
}

[data-aos="progress-anim"] {
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.5s ease;

  &.aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}

.fp-watermark {
  display: none;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--light-grey);
  color: var(--white);

  overflow-x: hidden;

  &.disabled {
    overflow-y: scroll;
    position: fixed;

    width: 100%;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

.lottie-animation {
  opacity: .25;
  path {
    stroke-width: 1px;
  }

  rect {
    fill: transparent;
  }
}

#site {
  position: relative;
  /*overflow: hidden;*/
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.visible-overflow-page {
    overflow: visible;
    clip-path: inset(0 0 0 0);

    @mixin tab-sm {
      overflow: hidden;
    }
  }
}

.main {
  flex-grow: 1;
}

%default-transition {
  transition: ease-in-out 0.25s;
}

.cont {
  margin: 0 auto;
  max-width: 1280px;
  width: perc(1280);

  @mixin tab-md {
    width: 89.33%;
  }
}

section {
  position: relative;
  overflow-x: hidden;
}

.sticky:before,
.sticky:after {
  content: '';
  display: table;
}

.title {
  &.scroll-target {
    clip-path: inset(0 0 0 0);
    h1,h2,h3 {
      transform: translateX(-10%) translateY(-100%) rotate(-5deg) scale(0.6);
      transition: transform 0.8s ease, opacity 1s ease;
      opacity: 0;
    }

    &.animation {
      h1,h2,h3 {
        transform: translateX(0) translateY(0) rotate(0) scale(1);
        opacity: 1;
      }
    }
  }
}

/*Home*/

.first-section {
  background: var(--gradient-main);
  padding-top: ac(100px, 81px);
  min-height: 600px;
  height: calc(100vh - 80px);
  position: relative;
  overflow: hidden;
  clip-path: inset(0 0 0 0);

  .lottie-animation {
    position: absolute;
    right: -7%;
    bottom: -1%;
    width: 100%;
    transform: scale(1.02);


    @mixin tab-sm {
      right: -5%;
      bottom: -2%;
      transform: scale(1.05);
      transform-origin: bottom;
    }

    @mixin mob-lg {
      bottom: -2%;
      right: -10%;
      transform: none;
      width: 150%;
    }
  }

  .decor-lines {
    @mixin aspect-ratio 2354, 1454;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: ac(2354px, 1800px);

    img {
      &.only-phone {
        display: none;
      }
    }

    @mixin mob-xl {
      top: 20px;
      @mixin aspect-ratio 1668, 1318;
      position: absolute;
      width: 1668px;
      transform: translateX(-45%) translateY(0);

      img {
        &.only-desk {
          display: none;
        }

        &.only-phone {
          display: block;
        }
      }
    }
  }

  .cont {
    height: 100%;
  }

  &__wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    .title {
      text-align: center;
      max-width: 720px;
      padding-bottom: ac(38px, 30px);

      h1 {
        span {
          white-space: nowrap;
        }
      }
    }

    .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      font-family: var(--font-main);
      color: var(--white);
      font-size: ac(70px, 28px);
      font-weight: 500;
      line-height: ac(80px, 36px);
      padding-bottom: ac(32px, 30px);
      opacity: 0;
      transition: opacity .3s ease;
      transform: translateY(ac(-7px, 0px));

      &.active {
        opacity: 1;
      }
    }

    .title-row {
      display: flex;

      .title-line {
        display: flex;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: ac(-4px, -2px);
          right: 0;
          height: ac(4px, 2px);
          background: var(--white);
        }

      }

      .changing-box {
        position: relative;
        height: 100%;
        transition: width 0.8s ease;
		overflow: hidden;

        .changing-elem {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(0);
          white-space: nowrap;
          opacity: 0;
          transition: opacity .5s ease, transform .35s ease;
          transform-origin: left;

          &.active {
            opacity: 1;
            transform: translateY(-50%);
          }

        }
      }
    }

    .subtitle {
      padding-bottom: 27px;
      text-align: center;
      max-width: ac(632px, 530px);
      p {
        font-size: ac(20px, 16px);
        font-weight: 500;
        line-height: ac(30px, 19px);
      }

      h2 {
        font-size: ac(20px, 16px);
        font-weight: 500;
        line-height: ac(30px, 19px);
      }

      @mixin mob-lg {
        padding-bottom: 50px;
      }

      @mixin mob-sm {
        padding-bottom: 30px;
      }
    }

    .buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 22px;

      @mixin mob-lg {
        grid-template-columns: repeat(1, 1fr);
        transform: translateY(22px);

        .btn {
          width: 100%;
        }
      }

      @mixin mob-sm {
        transform: none;
      }
    }
  }

  h1,
  p {
    color: var(--white);

    a {
      color: inherit;
    }
  }
}

.marquee-section {
  background: var(--white);
  padding: ac(25px, 30px) 0;

  .marquee-content {
    &__item {
      position: relative;
      padding-right: ac(34px, 16px);
      margin-right: ac(30px, 12px);
      font-size: ac(18px, 16px);
      line-height: ac(28px, 20px);

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 4px;
        height: 4px;
        background: var(--main);
        border-radius: 50%;
      }

      &:after {
        content: "";
        position: absolute;
        right: ac(34px, 16px);
        width: 0;
        height: 2px;
        bottom: 0;
        background: var(--main);
        transition: width .3s ease;
      }

      .vacancy-name {
        color: var(--dark-blue);
        font-family: var(--font-second);
        transition: color .3s ease;
      }

      &:hover {

        .vacancy-name {
          color: var(--main);
        }

        &:after {
          width: calc(100% - ac(34px, 16px));
          right: auto;
          left: 0;
        }
      }
    }
  }
}

.text-section {
  &.recruiting-sec {
    position: relative;
    padding-top: ac(110px, 66px);
    padding-bottom: ac(126px, 140px);
    max-width: 1920px;
    margin: 0 auto;
    clip-path: inset(0 0 0 0);

    @mixin min-media 1800 {
      padding-bottom: 150px;
    }

    &:before {
      position: absolute;
      top: 35px;
      right: 80px;
      content: "";
      background-image: url("../images/decors/bg-logo.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: ac(240px, 110px);
      height: ac(240px, 110px);

      @mixin mob-lg {
        top: 24px;
        right: 5px;
      }
    }

    .title {
      h2 {
        font-size: ac(24px, 20px);
        font-weight: 600;

        line-height: ac(32px, 29px);
      }
    }

    .text-section {
      &__wrap {
        max-width: ac(548px, 420px);
        margin: 0 auto;

        @mixin min-media 1800 {
          max-width: 640px;
        }

        .title, .text-content, .btn {
          transform: translateX(ac(42px, 0px));

          @mixin desk-sm {
            transform: none;
          }

          @mixin tab {
            transform: translateX(ac(0px, 60px));
          }

          @mixin tab-sm {
            transform: none;
          }
        }

        @mixin tab-sm {
          max-width: 100%;
          position: relative;
          z-index: 1;

          .title {
            padding-bottom: 34px;
          }
        }
      }
    }

    .photo {
      width: ac(388px, 180px);

      @mixin min-media 1800 {
        width: 420px;
      }

      img {
        transition: transform .3s ease;
      }

      &:hover {
        img {
          transform: scale(1.02);
        }
      }

      &.photo-left {
        @mixin aspect-ratio 388, 540;
        position: absolute;
        left: 0;
        bottom: 0;

        &.new-img {
          @mixin aspect-ratio 1020, 840;
          position: absolute;

          img {
            object-fit: contain;
            object-position: bottom;
          }
        }
      }

      &.photo-right {
        @mixin aspect-ratio 388, 236;
        position: absolute;
        right: 0;
        bottom: 0;

        &.new-img {
          @mixin aspect-ratio 922, 924;
          position: absolute;

          img {
            object-fit: contain;
            object-position: bottom;
          }
        }
      }

      @mixin tab {
        width: ac(388px, 210px);

        &.photo-right {
          width: ac(388px, 260px);
          &.new-img {
            width: ac(388px, 260px);

            img {
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }

      @mixin tab-sm {
        &.photo-left {
          display: none;
        }
        &.photo-right {
          position: static !important;
          right: auto;
          bottom: auto;
          width: 100%;
          margin-bottom: 30px;

          &.new-img {
            width: 100%;
            @mixin aspect-ratio 1, 1;

            img {
              object-fit: contain;
              object-position: center;
            }
          }

          &:before {
            content: none;
          }

          img {
            position: static;
          }
        }
      }
    }

    @mixin tab-sm {
      padding-bottom: 70px;
    }
  }
}

.industries-section {
  background: var(--white);
  padding-top: ac(179px, 70px);
  padding-bottom: 0;
  overflow: visible;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .title {
    text-align: center;
    padding-bottom: 30px;
  }

  .industries-slider {
    max-width: 100%;
    width: 100%;
    overflow: visible;
    margin-bottom: ac(-170px, -120px);

    @mixin min-media 652 {
      .swiper-wrapper {
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: ac(78px, 20px);
        grid-row-gap: ac(63px, 20px);
        width: 100% !important;

        @mixin tab-lg {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    @mixin mob-xl {
      max-width: 335px;
      margin-bottom: 0;

      .industries-card {
        width: 100%;
      }
    }
  }

  @mixin mob-xl {
    padding-bottom: 70px;
    overflow: hidden;
  }
}

.text-section {
  &.technology-sec {
    padding-top: ac(400px, 200px);
    padding-bottom: ac(95px, 70px);
    .text-section {
      &__wrap {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        padding-top: 136px;

        .info-block {
          width: 44.06%;
          margin-left: auto;
          margin-right: ac(68px, 0px);

          &:before {
            position: absolute;
            top: ac(0px, 40px);
            left: calc((100% - 44%) - ac(68px, 0px));
            content: "";
            background-image: url("../images/decors/bg-logo.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: ac(257px, 120px);
            height: ac(257px, 120px);
            transform: translateY(-62%);
          }

          @mixin desk-sm {
            width: 50%;
            margin-right: 0;
          }
        }

        .text-content {
          max-width: 470px;
          padding-bottom: ac(24px, 32px);
        }

        .photo {
          @mixin aspect-ratio 466, 500;
          position: absolute;
          left: 0;
          bottom: 0;
          width: ac(466px, 300px);

          img {
            transition: transform .3s ease;
          }

          &:hover {
            img {
              transform: scale(1.02);
            }
          }

          &.new-img {
            @mixin aspect-ratio 1080, 1064;
            position: absolute;

            img {
              object-fit: contain;
            }
          }
        }

        @mixin tab-sm {
          padding-top: 0;

          .photo {
            @mixin aspect-ratio 335, 393;
            position: static !important;
            width: 100%;
            margin-bottom: 30px;

            img {
              object-fit: contain;
            }
          }

          .info-block {
            width: 100%;

            &:before {
              top: 20px;
              right: -10px;
              z-index: -1;
              left: auto;
              transform: translateY(-50%);

              @mixin mob-xl {
                transform: none;
                top: -50px;
              }
            }
          }

          .title {
            max-width: 370px;
          }
        }
      }
    }

    @mixin mob-xl {
      padding-top: 68px;
    }
  }
}

.latest-jobs-section {
  padding-top: ac(95px, 70px);
  padding-bottom: ac(119px, 60px);
  overflow: visible;
  position: relative;
  z-index: 1;

  &__bg {
    background: var(--gradient-main);
    height: ac(560px, 400px);
    top: ac(371px, 307px);
    left: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
    clip-path: inset(0 0 0 0);

    .lottie-animation {
      bottom: -2%;
      opacity: .24;
      position: absolute;
      right: -10%;
      width: 100%;
      /*transform: rotate(10deg);*/
    }

    .decor-lines {
      @mixin aspect-ratio 2674, 2674;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-60%) translateX(-46%);
      width: ac(2674px, 1600px);

      img {
        &.only-phone {
          display: none;
        }
      }

      @mixin mob-xl {
        @mixin aspect-ratio 1603, 990;
        position: absolute;
        top: 0;
        width: 1603px;
        transform: translateY(-5%) translateX(-50%);

        img {

          &.only-desk {
            display: none;
          }

          &.only-phone {
            display: block;
          }
        }
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ac(36px, 32px);

    .title {
      max-width: 50%;

      @mixin mob-sm {
        max-width: 70%;
      }
    }

    .btn {
      padding: 10px ac(41px, 44px);
      transform: translateY(ac(0px, 2px));

      @mixin mob-sm {
        padding: 10px 20px;
      }
    }
  }

  .latest-jobs-slider {
    width: 100%;
    max-width: ac(950px, 700px);
    /*max-width: ac(460px, 335px);*/
    overflow: visible;
    margin-left: 0;

    .job-card {
      width: ac(460px, 335px);
    }

    @mixin tab {
      max-width: ac(940px, 690px);
    }

    @mixin tab-sm {
      max-width: ac(460px, 335px);
    }

    @mixin mob-xl {
      margin-left: auto;

      .swiper-pagination {
        margin-top: 68px;
      }
    }
  }
}

.talk-section {
  position: relative;
  z-index: 2;
  padding-top: ac(119px, 35px);
  padding-bottom: 0;

  &.interior-pages {
    padding-top: 0;
    margin-top: ac(-184px, -100px);
  }

  &__wrap {
    background: var(--white);
    padding: ac(64px, 28px) ac(80px, 30px) ac(74px, 32px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    clip-path: inset(0 0 0 0);

    .cursor-aura {
      position: fixed;
      left: 0;
      top: 0;
      /*!*background: var(--green);*!*/
      background: radial-gradient(var(--turquoise), color-mod(var(--turquoise) a(0%)) 40%);
      /*!*filter: blur(20px);*!*/
      width: ac(450px, 225px);
      height: ac(450px, 225px);
      margin-top: ac(-225px, -112px);
      margin-left: ac(-225px, -112px);
      border-radius: 50%;
      transition: transform .05s, opacity .3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      pointer-events: none;
      z-index: -1;

      &:before {
        background: var(--main);
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

      &.active {
        opacity: .4;
      }

      @mixin tab {
        display: none;
      }
    }

    @mixin tab-sm {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__info {
    width: 41.70%;

    @mixin tab {
      width: 47%;
    }

    @mixin tab-sm {
      width: 100%;
    }
  }

  &__form {
    width: 49.29%;
    padding-top: ac(28px, 24px);

    @mixin tab-sm {
      width: 100%;
    }
  }

  .title {
    padding-bottom: 10px;

    @mixin tab-sm {
      padding-bottom: 30px;
    }
  }

  @mixin mob-xl {
    padding-top: 9px;
  }
}

/* Interior */

.first-interior-section {
  background: var(--gradient-main);
  position: relative;
  overflow: hidden;
  clip-path: inset(0 0 0 0);

  &__wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  .title {
    h1 {
      line-height: 138%;
    }
  }

  h1,
  p {
    color: var(--white);

    a {
      color: inherit;
    }
  }
}

.grecaptcha-badge {
  z-index: 10;
}
