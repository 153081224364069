.first-interior-section {

    &.blogs-page {
        padding-top: ac(198px, 190px);
        padding-bottom: ac(110px, 80px);

        .lottie-animation {
            bottom: -1%;
            opacity: .34;
            position: absolute;
            right: -10%;
            width: 120%;

            &:before {
                inset: 0;
                position: absolute;
                content: "";
                background: linear-gradient(134.89deg, color-mod(#3033EF a(0%)) 0%, rgba(48,51,239,1) 100%);
                z-index: 1;
            }

            @mixin mob-xl {
                opacity: .5;
                &:before {
                    content: none;
                }
            }
        }

        .title {
            width: 100%;
            text-align: center;
            max-width: ac(600px, 350px);
            margin: 0 auto;
        }

        .text-content {
            padding-top: ac(40px, 30px);
            margin: 0 auto;
            text-align: center;
        }

        .decor-lines {
            @mixin aspect-ratio 1088, 794;
            position: absolute;
            right: 34.10%;
            top: 69px;
            width: 75.56%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: right;
            }

            @mixin mob-xl {
                right: 10%;
                min-width: 500px;
            }
        }

        .logo-decor {
            @mixin aspect-ratio 188, 185;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-62%);
            width: ac(188px, 140px);
            opacity: .4;
            pointer-events: none;

            img {
                object-fit: contain;
            }

            @mixin mob-xl {
                opacity: .3;
            }
        }
    }
}

.search-section {
    &.blogs-page {
        margin-top: ac(-32px, -27px);
    }
}

.blogs-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(20px, 16px);
    padding-bottom: ac(78px, 80px);

    @mixin tab {
        grid-column-gap: 10px;
    }

    @mixin tab-md {
        grid-template-columns: repeat(2, 1fr);
    }

    @mixin mob-lg {
        grid-template-columns: repeat(1, 1fr);
    }
}

.result-section {
    &.blogs-page {
        padding-top: ac(80px, 60px);
        padding-bottom: ac(304px, 190px);

        .result-section-bg {
            height: ac(755px, 400px);

            .lottie-animation {
                bottom: -2%;
                opacity: .24;
                position: absolute;
                right: -10%;
                width: 120%;
                /*transform: rotate(10deg);*/
            }


            .decor-lines {
                @mixin aspect-ratio 1756, 1282;
                position: absolute;
                top: ac(68px, 40px);
                right: 15.90vw;
                width: 121.94%;

                img {
                    object-fit: contain;
                }
            }
        }
    }
}