@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-HeavyItalic.woff2') format('woff2'),
    url('../fonts/Roobert-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-MediumItalic.woff2') format('woff2'),
    url('../fonts/Roobert-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.woff2') format('woff2'),
    url('../fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.woff2') format('woff2'),
    url('../fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-RegularItalic.woff2') format('woff2'),
    url('../fonts/Gilroy-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-BoldItalic.woff2') format('woff2'),
    url('../fonts/Gilroy-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-BoldItalic.woff2') format('woff2'),
    url('../fonts/Roobert-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-SemiboldItalic.woff2') format('woff2'),
    url('../fonts/Gilroy-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-Regular.woff2') format('woff2'),
    url('../fonts/Roobert-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-Light.woff2') format('woff2'),
    url('../fonts/Roobert-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-Heavy.woff2') format('woff2'),
    url('../fonts/Roobert-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.woff2') format('woff2'),
    url('../fonts/Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-MediumItalic.woff2') format('woff2'),
    url('../fonts/Gilroy-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-Medium.woff2') format('woff2'),
    url('../fonts/Roobert-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-Bold.woff2') format('woff2'),
    url('../fonts/Roobert-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-LightItalic.woff2') format('woff2'),
    url('../fonts/Gilroy-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light.woff2') format('woff2'),
    url('../fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-SemiBold.woff2') format('woff2'),
    url('../fonts/Roobert-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-LightItalic.woff2') format('woff2'),
    url('../fonts/Roobert-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-RegularItalic.woff2') format('woff2'),
    url('../fonts/Roobert-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Semibold.woff2') format('woff2'),
    url('../fonts/Gilroy-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('../fonts/Roobert-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Roobert-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?54zl2y');
    src:  url('../fonts/icomoon.eot?54zl2y#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?54zl2y') format('woff2'),
    url('../fonts/icomoon.ttf?54zl2y') format('truetype'),
    url('../fonts/icomoon.woff?54zl2y') format('woff'),
    url('../fonts/icomoon.svg?54zl2y#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
    content: "\e90c";
}
.icon-filters:before {
    content: "\e90b";
}
.icon-check:before {
    content: "\e90a";
}
.icon-box:before {
    content: "\e908";
}
.icon-arrow-down:before {
    content: "\e907";
}
.icon-checked:before {
    content: "\e909";
}
.icon-mail:before {
    content: "\e906";
}
.icon-coin:before {
    content: "\e905";
}
.icon-linkedin:before {
    content: "\e900";
}
.icon-facebook:before {
    content: "\e901";
}
.icon-twitter:before {
    content: "\e902";
}
.icon-location:before {
    content: "\e903";
}
.icon-bag:before {
    content: "\e904";
}
