.industries-card {
    background: var(--white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(40px, 30px) ac(40px, 30px) ac(40px, 50px);
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
    height: auto;
    perspective: 1000px;
    transform-style: preserve-3d;

    &__logo {
        background: var(--gradient-main);
        width: ac(67px, 68px);
        height: ac(67px, 68px);
        margin-bottom: ac(19px, 11px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: transform .3s ease;
        transform: translateZ(0);

        img {
            max-width: 36px;
            height: auto;
            object-fit: contain;
        }
    }

    &__title {
        padding-bottom: ac(12px, 16px);
        transition: transform .3s ease;
        transform: translateZ(0);
    }

    &__text {
        max-width: 276px;
        max-height: calc(ac(30px, 23px) * 5);
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--main) var(--light-grey);
        transition: transform .3s ease;
        transform: translateZ(0);
        padding-right: 10px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: var(--light-grey);
            border-radius: 0;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--main);
            border-radius: 0;
        }

        @mixin mob-xl {
            max-width: 100%;
        }
    }

    &:hover {
        .industries-card__logo {
            transform: translateZ(40px);
        }

        .industries-card__title {
            transform: translateZ(30px);
        }

        .industries-card__text {
            transform: translateZ(25px);
        }
    }
}

.job-card {
    padding: ac(40px, 28px) ac(60px, 30px) ac(59px, 62px);
    background: var(--white);
    box-shadow: 0px 0px ac(60px, 30px) rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: auto;

    &__title {
        max-width: ac(296px, 256px);
        padding-bottom: ac(16px, 16px);
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: ac(0px, 10px);

        li {
            font-size: 16px;
            font-family: var(--main);
            color: var(--dark-blue);
            font-weight: 400;
            line-height: ac(28px, 23px);
            margin-bottom: 10px;

            &:not(:last-child) {
                margin-right: ac(46px, 20px);
            }
        }
    }

    &__text {
        @mixin max-line-length 5;
    }

    .btn {
        position: absolute;
        left: ac(60px, 30px);
        bottom: 0;
        transform: translateY(50%);
        min-width: 168px;

        @mixin mob-xl {
            left: 50%;
            transform: translateY(50%) translateX(-50%);
        }
    }

    &.job-card_lg {
        display: flex;
        flex-direction: column;
        padding: 30px;
        min-height: 216px;

        .job-card__title {
            max-width: 100%;
            padding-bottom: 0;
            margin-right: 10px;
        }

        .job-card__text {
            margin: 20px 0;
        }

        .job-card__salary {
            font-size: 20px;
            font-weight: 500;
            line-height: 124.8%;
            color: var(--dark-blue);
        }

        .job-card__details {
            display: flex;
            align-items: center;
            margin-top: auto;

            &-item {
                display: flex;
                align-items: center;
                line-height: 131.5%;

                i {
                    margin-right: 12px;
                }

                &:not(:last-child) {
                    margin-right: 30px;
                }
            }

            &:container(width <= 370px) {
                flex-direction: column;
                align-items: flex-start;

                .job-card__details-item {
                    &:not(:last-child) {
                        margin: 0 0 20px 0;
                    }
                }
            }
        }

        &:container(width <= 475px) {
            .flex {
                flex-direction: column;
            }

            .job-card__title {
                margin: 0 0 10px;
            }
        }
    }
}

.event-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: ac(60px, 45px);
    background: var(--white);
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &__photo {
        @mixin aspect-ratio 413, 260;
        width: 100%;
        margin-bottom: ac(30px, 20px);

        img {
            transition: transform .25s ease;
        }
    }

    &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: ac(30px, 20px);
        padding-right: ac(30px, 20px);

        .title {
            padding-bottom: ac(12px, 8px);

            h3 {
                letter-spacing: -1.05px;
            }
        }

        .date {
            padding-bottom: ac(20px, 10px);
            font-size: ac(16px, 14px);
            font-family: var(--main);
            color: var(--dark-blue);
            font-weight: 400;
            line-height: 144%;


        }

        .text-content {
            width: 100%;
            max-height: calc((ac(18px, 16px) * 1.45) * 8);

            .simplebar-track.simplebar-vertical {
                width: 4px;
                background: transparent;
                border-radius: 2px;
                transform: translateX(4px);

                .simplebar-scrollbar {
                    background: var(--main);
                    border-radius: 2px;

                    &:before {
                        content: none;
                    }
                }
            }

            p {
                line-height: 144.3%;
            }
        }
    }

    .btn {
        position: absolute;
        bottom: 0;
        left: ac(60px, 40px);
        transform: translateY(50%);

        @mixin tab {
            left: 50%;
            transform: translateY(50%) translateX(-50%);
        }
    }

    &:hover {
        img {
            transform: scale(1.03);
        }
    }
}

.blog-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--white);
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding-bottom: ac(30px, 25px);

    .category-name {
        position: absolute;
        z-index: 1;
        top: ac(20px, 16px);
        left: ac(20px, 16px);
        min-width: ac(131px, 120px);
        font-size: ac(16px, 14px);
        font-weight: 600;
        line-height: ac(20px, 18px);
        font-family: var(--font-main);
        color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ac(12px, 10px) ac(20px, 10px);
        background: var(--gradient-category);
    }

    &__photo {
        @mixin aspect-ratio 413, 260;

        width: 100%;
        margin-bottom: ac(30px, 20px);

        img {
            transition: transform .25s ease;
        }
    }

    &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: ac(30px, 20px);
        padding-right: ac(30px, 20px);
        flex: 1;

        .title {
            padding-bottom: ac(20px, 12px);

            h3 {
                line-height: 144.3%;
                letter-spacing: -1.05px;
            }
        }

        .date {
            padding-bottom: 10px;
            font-size: ac(16px, 14px);
            font-family: var(--main);
            color: var(--dark-blue);
            font-weight: 400;
            line-height: 144%;
        }

        .text-content {
            @mixin max-line-length 8;
            margin-bottom: ac(49px, 20px);
        }

        .more-info {
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-bottom: ac(11px, 5px);

            .view-more, .past-time {
                font-size: ac(16px, 14px);
                font-family: var(--main);
                line-height: 130%;
                max-width: 48%;
                font-weight: 400;
            }

            .view-more {
                color: var(--main);
                position: relative;
                font-weight: 600;
                transition: color .25s ease;
                cursor: pointer;

                &:before {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 2px;
                    background: var(--main);
                    content: "";
                    transition: width .3s ease, background-color .25s ease;
                    width: 0;
                }

                &:hover {
                    color: var(--dark-blue);
                    &:before {
                        background-color: var(--dark-blue);
                        right: auto;
                        left: 0;
                        width: 100%;
                    }
                }
            }

            .past-time {
                color: var(--dark-blue);
                padding-right: ac(6px, 0px);
            }
        }
    }

    &:hover {
        img {
            transform: scale(1.03);
        }
    }
}

.core-values-card {
    position: relative;
    border: 4px solid color-mod(#fff a(36%));
    border-top: 0;
    clip-path: inset(-100px 0 0 0);
    padding: ac(68px, 50px) ac(36px, 20px) ac(60px, 25px);
    min-height: ac(381px, 200px);
    transition: border-color .4s ease;

    h1,h2,h3,h4,h5,h6,p,a,li {
        color: var(--white);
    }

    &__logo {
        width: ac(88px, 70px);
        height: ac(88px, 70px);
        position: absolute;
        left: ac(74px, 40px);
        top: 0;
        transform: translateY(-100%);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: translateY(35%);
            transition: transform .4s ease;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            height: 4px;
            bottom: 0;
            background: color-mod(#fff a(36%));
            width: 1000px;
            transition: background-color .3s ease;
        }

        &:before {
            left: ac(-26px, -20px);
            transform: translateX(-100%);
        }

        &:after {
            right: ac(-26px, -20px);
            transform: translateX(100%);
        }
    }

    .title {
        padding-bottom: ac(20px, 15px);
        transition: transform .25s ease;
        transform: translateZ(0);

        h3 {
            font-weight: 500;
        }
    }

    .text-content {
        width: 100%;
        max-height: calc((16px * 1.55) * 10);

        .simplebar-track.simplebar-vertical {
            width: 4px;
            background: transparent;
            border-radius: 2px;
            transform: translateX(4px);

            .simplebar-scrollbar {
                background: color-mod(#fff a(36%));
                border-radius: 2px;

                &:before {
                    content: none;
                }
            }
        }

        @mixin tab {
            max-height: calc((16px * 1.55) * 12);
        }

        @mixin tab-sm {
            max-height: calc((16px * 1.55) * 14);
        }

        @mixin mob-xl {
            max-height: none;
        }
    }

    &:hover {
        border-color: color-mod(#fff a(100%));

        .core-values-card__logo {
            &:after,
            &:before {
                background: color-mod(#fff a(100%));
            }
        }
    }
}

.technology-card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(30px, 20px);
    background: var(--white);
    box-shadow: var(--shadow-main);

    &__logo {
        position: relative;
        width: ac(285px, 200px);
        margin-right: ac(30px, 20px);

        .icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: ac(128px, 80px);
            height: ac(128px, 80px);
            z-index: 1;
        }

        .photo {
            @mixin min-media 652 {
                @mixin aspect-ratio 285, 222;
            }
            width: 100%;

            img {
                filter: saturate(0);
                transition: transform .3s ease;
            }

            &:after {
                content: "";
                position: absolute;
                inset: 0;
                background: linear-gradient(119.91deg, rgba(40, 187, 239, 0.8) 0%, rgba(48, 67, 239, 0.8) 105.72%);
               /* opacity: .8;*/
                opacity: 1;
            }
        }

        @mixin mob-xl {
            width: 100%;
            margin-bottom: 20px;

            .photo {
                height: 200px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .icon {
                width: 100px;
                height: 100px;
            }
        }
    }

    &__info {
        flex: 1;

        .title {
            width: 100%;
            padding-bottom: ac(18px, 16px);
            border-bottom: 1px solid color-mod(var(--main) a(10%));
        }

        .btn-more {
            margin-top: auto;
        }
    }

    &__text {
        margin-top: ac(20px, 16px);
        margin-bottom: 8px;
        overflow: hidden;
        max-height: calc((ac(18px, 16px) * 1.45) * 4);
        transition: max-height .4s ease;

        &.active {
            max-height: 1000px;
            transition: max-height 1s ease;
        }
    }

    @mixin mob-xl {
        flex-direction: column;
    }

    &:hover {
        .photo {
            img {
                transform: scale(1.03);
            }
        }
    }

}