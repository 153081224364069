.ntp-popup {
    padding: 8px !important;
    overflow: hidden !important;
    max-height: none !important;

    &.event-ntp-popup {
        .popup__content {
            min-height: auto;
        }
    }

    .popup {
        &__container {
            padding: 30px 10px 30px 20px;
            position: relative;
            z-index: 1;

            @mixin mob-lg {
                padding-bottom: 20px;
            }
        }

        &__title {
            font-weight: 500;
            margin-bottom: ac(30px, 20px);
            font-size: ac(24px, 20px);
            line-height: ac(32px, 29px);
        }

        &__content {
            max-height: 58vh;
            padding-right: 10px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: #1B105D var(--light-gray);
            min-height: 200px;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background: var(--light-gray);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background: #1B105D;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #1B105D;
            }

            @mixin mob-lg {
                max-height: 58vh;
            }
        }

        &__btn-close {
            z-index: 10;
            top: 10px;
            right: 10px;

            .cross-clip {
                width: ac(20px, 16px);
                height: ac(20px, 16px);
                background: #1B105D;
                transition: all .25s ease;
                clip-path: polygon(13% 0%, 0% 13%, 39% 50%, 0% 87%, 13% 99%, 50% 61%, 87% 99%, 99% 86%, 61% 50%, 99% 13%, 87% 0%, 50% 38%);
            }

            span {
                background: #1B105D;
            }

            &:hover {
                span {
                    background: var(--turquoise);
                }
            }
        }

    }

    .form {
        justify-content: flex-start;
        align-items: flex-start !important;

        .form-grid {
            grid-column-gap: ac(30px, 15px);
            grid-row-gap: ac(30px, 20px);
            padding-bottom: ac(20px, 10px);
        }
    }

    .file-input {
        grid-column: 1 / -1;

        input {
            z-index: -1;
        }
    }

    .checkbox {
        padding-bottom: 10px;

        .checkbox-box {
            min-width: 18px;
        }

        p {
            a {
                white-space: nowrap;
                transition: color .3s ease;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 2px;
                    background: var(--main);
                    width: 0;
                    transition: width .25s ease;
                }

                &:hover {
                    color: var(--turquoise);

                    &:before {
                        width: 100%;
                        right: auto;
                        left: 0;
                        background: var(--turquoise);
                    }
                }
            }
        }

/*        &:hover {
            .checkbox-box {
                border-color: var(--turquoise);
            }
        }*/
    }

    .btn {
        margin-top: 10px;
        width: 100%;
        max-width: 226px;

        @mixin mob {
            max-width: 100%;
        }
    }
}