.blog-view-section {
    padding: ac(190px, 160px) 0 20px;
    min-height: ac(615px, 515px);
    height: auto;
    /*background: url("../images/decors/blog-view-hero-bg.svg") no-repeat center / cover;*/
    background: linear-gradient(98.08deg, #26DAEF -0.45%, #302DEE 100.98%);

    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;


    .lottie-animation {
        bottom: -1%;
        opacity: .24;
        position: absolute;
        right: -10%;
        width: 120%;

        &:before {
            inset: 0;
            position: absolute;
            content: "";
            background: linear-gradient(98.08deg, color-mod(#26DAEF a(0%)) 0%, #302DEE 100%);
            z-index: 1;
        }

        @mixin mob-xl {
            opacity: .5;
            &:before {
                content: none;
            }
        }
    }

    .cont {
        position: relative;
        z-index: 1;
        max-width: 1064px;
        width: perc(1064);
    }

    &:before {
        content: '';
        position: absolute;

    }
}

.blog-heading {
    max-width: 1064px;
    margin-bottom: 90px;

    &__category {
        font-size: 16px;
        padding: 12px 33px;
        text-align: center;
        color: var(--blue);
        background: var(--whitesmoke);
        margin-bottom: 16px;
        width: fit-content;
        line-height: 125%;
    }

    &__title {
        line-height: 144.3%;
    }
}

.blog-view-details {
    border-top: 1px solid var(--white);
    padding-top: 20px;
    margin-top: auto;

    p {
        font-size: 20px;
        font-weight: 600;
    }
}

.blog-content {
    padding: ac(100px, 60px) 0 60px;
    background: var(--white);

    .text-content {
        p {
            font-size: ac(20px, 16px);
        }
    }
}

.blog-content__resent-posts {
    max-width: 305px;
    flex: 0 0 auto;
    margin-top: -20px;

    &-title {
        margin-bottom: 30px;
    }

    .blog-card {
        padding-bottom: 20px;

        &__photo {
            min-height: 215px;
        }

        &__info {
            padding: * 20px;
        }

        .text-content {
            margin-bottom: 20px;

            p {
                font-size: 16px;
            }

            @mixin max-line-length 6;
        }

        .title {
            padding-bottom: 12px;
        }
    }

    @mixin tab {
        max-width: 100%;
    }
}

.resent-posts-list {
    display: grid;
    grid-gap: 30px;

    @mixin tab {
        grid-auto-flow: column;
    }

    @mixin tab-md {
        grid-auto-flow: row;
    }
}

.share-block {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.socials-link {
    size: 40px;
    border-radius: 50%;
    background: var(--light-turquoise);
    color: var(--main);
    transition: 0.3s ease;
    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: var(--main);
        color: var(--white);
    }
}

.blog-content__logo {
    right: 50px;
    bottom: -26px;
    position: absolute;

    @mixin tab {
        size: 150px;
        right: 0;
        bottom: 0;
        transform: translateY(50%);
    }

    @mixin mob {
        size: 120px;
    }
}

.blog-view-bg {
    height: ac(344px, 270px);
    background: url("../images/decors/blue-bottom-bg.svg") no-repeat center / cover;
    /*background: linear-gradient(134.89deg, #25E1EF 0%, #3024ED 100.2%);*/

    overflow: hidden;
    position: relative;

    .lottie-animation {
        bottom: -5%;
        opacity: .22;
        position: absolute;
        right: -10%;
        width: 120%;
    }
}