h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: var(--font-main);
  color: var(--main);
}

h1 {
  font-size: ac(70px, 32px);
  font-weight: 500;

  line-height: ac(80px, 39px);
}

h2 {
  font-size: ac(48px, 30px);
  font-weight: 500;

  line-height: ac(60px, 36px);
}

h3 {
  font-size: ac(24px, 20px);
  font-weight: 600;

  line-height: ac(32px, 29px);
}

h4 {
  font-size: ac(32px, 20px);
  font-weight: 500;

  line-height: 130%;
}

h5 {
  font-size: ac(26px, 20px);
  font-weight: 500;

  line-height: 1.5;
}

h6 {
  font-size: ac(22px, 18px);
  font-weight: 500;

  line-height: 2;
}

p {
  font-size: ac(18px, 16px);
  font-weight: 400;

  line-height: ac(30px, 23px);
}

a {
  font-size: ac(18px, 16px);
  font-weight: 600;
  line-height: ac(30px, 23px);

  color: var(--main);
  display: inline-block;
}

.text-content {
  h1,h2,h3 {
    font-weight: 600;
    color: var(--main);
    line-height: 35px;
    padding-bottom: 20px;
  }

  h1 {
    font-size: ac(30px, 24px);
  }

  h2 {
    font-size: ac(24px, 22px);
  }

  h3 {
    font-size: ac(22px, 20px);
  }

  p {
    &:not(:last-of-type) {
      padding-bottom: 30px;
    }

    /*& + ol, & + ul {
      margin-top: 20px;
    }*/
  }

  a {
    font-size: ac(18px, 16px);
    font-weight: 400;

    line-height: ac(30px, 23px);
    color: var(--turquoise);
    display: inline-block;
    transition: color .25s ease;

    &:hover {
      color: var(--main);
      text-decoration: underline;
    }
  }

  picture, img {
    margin-bottom: 30px;
  }

  ul, ol {
    /*margin-bottom: 20px;*/
    padding-left: 33px;

    li {
      font-size: 20px;
      color: var(--main);
      line-height: 154.02%;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  &.updated-text-styles {
    h2 {
      font-size: ac(48px, 30px);
      font-weight: 500;

      line-height: 144.3%;
    }

    p {
      line-height: 144.3%;

      &:not(:last-child) {
        padding-bottom: ac(11px, 10px);
      }
    }
  }

  &.larger-size {
    p {
      font-size: ac(20px, 16px);
      font-weight: 400;

      line-height: 156%;

      &:not(:last-child) {
        padding-bottom: ac(30px, 20px);
      }
    }

    a {
      font-size: ac(20px, 16px);
      font-weight: 500;

      line-height: 156%;

      color: var(--turquoise);
      display: inline-block;
      transition: color .25s ease;

      &:hover {
        color: var(--main);
        text-decoration: underline;
      }
    }
  }

  &.smaller-size {
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 154.3%;

      &:not(:last-child) {
        padding-bottom: ac(20px, 12px);
      }
    }

    a {
      font-size: 16px;
      font-weight: 600;

      line-height: 154.3%;

      color: var(--turquoise);
      display: inline-block;
      transition: color .25s ease;

      &:hover {
        color: var(--main);
        text-decoration: underline;
      }
    }
  }
}

.inline-text-content {

  h1 {
    font: 600 ac(48px, 30px) / 120.83% var(--font-main) !important;
    color: var(--main) !important;
  }

  h2 {
    font: 600 ac(30px, 24px) / 125.50% var(--font-main) !important;
    color: var(--main) !important;
  }

  h3 {
    font: 600 ac(24px, 20px) / 122.92% var(--font-main) !important;
    color: var(--main) !important;

    &:not(:last-child) {
      padding-bottom: ac(20px, 15px);
    }

    &:not(:first-child) {
      padding-top: ac(10px, 0px);
    }
  }

  h1,
  h2 {
    &:not(:last-child) {
      padding-bottom: ac(30px, 20px);
    }

    &:not(:first-child) {
      padding-top: ac(10px, 0px);
    }
  }

  h4 {
    font: 600 ac(22px, 18px) / 122.92% var(--font-main) !important;
    color: var(--main) !important;

    &:not(:last-child) {
      padding-bottom: ac(20px, 15px);
    }
  }

  h5 {
    font: 600 ac(20px, 16px) / 122.92% var(--font-main) !important;
    color: var(--main) !important;

    &:not(:last-child) {
      padding-bottom: ac(20px, 15px);
    }
  }

  h6 {
    font: 600 ac(18px, 15px) / 122.92% var(--font-main) !important;
    color: var(--main) !important;

    &:not(:last-child) {
      padding-bottom: ac(20px, 15px);
    }
  }

  p,
  li,
  td {
    font: 400 ac(20px, 16px) / 154.00% var(--font-main) !important;
    color: var(--main) !important;

    a {
      font: 600 ac(20px, 16px) / 154.00% var(--font-main) !important;
      color: var(--turquoise) !important;
      display: inline-block;
      transition: color .25s ease;
      text-decoration: none !important;

      &:hover {
        color: var(--main) !important;
        text-decoration: underline !important;
      }
    }

    b, strong {
      font-weight: 700 !important;
    }
  }

  p,
  ul,
  ol {
    &:not(:last-child) {
      padding-bottom: ac(20px, 15px);
    }
  }

  ul {
    list-style: none !important;
    padding-left: 0 !important;

    li {
      position: relative;
      padding-left: ac(36px, 16px) !important;

      &:before {
        content: "";
        position: absolute;
        left: ac(13px, 0px);
        top: ac(13px, 10px);
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background: var(--main);
      }

      /*&:not(:last-child) {
        margin-bottom: 5px;
      }*/
    }
  }

  ol {
    list-style-type: none !important;
    counter-reset: num;
    padding-left: 0 !important;

    li {
      padding-left: ac(36px, 16px) !important;
      position: relative;

      &:before {
        position: absolute;
        color: var(--main);
        top: 0;
        left: ac(8px, 0px);
        font: inherit;
        content: counter(num) ".";
        counter-increment: num;
      }

      /*&:not(:last-child) {
        margin-bottom: 5px;
      }*/
    }
  }

  &.inline-smaller {
    p,
    li,
    td {
      font: 400 ac(18px, 16px) / 154.00% var(--font-main) !important;

      a {
        font: 600 ac(18px, 16px) / 154.00% var(--font-main) !important;
      }

      b, strong {
        font-weight: 700 !important;
      }
    }

    ul {
      li {
        position: relative;
        padding-left: ac(30px, 14px) !important;

        &:before {
          content: "";
          position: absolute;
          left: ac(10px, 0px);
          top: ac(12px, 10px);
          border-radius: 50%;
          width: 4px;
          height: 4px;
          background: var(--main);
        }

        /*&:not(:last-child) {
          margin-bottom: 5px;
        }*/
      }
    }

    ol {
      li {
        padding-left: ac(30px, 14px) !important;
        position: relative;

        &:before {
          position: absolute;
          color: var(--main);
          top: 0;
          left: ac(8px, 0px);
          font: inherit;
          content: counter(num) ".";
          counter-increment: num;
        }

        /*&:not(:last-child) {
          margin-bottom: 5px;
        }*/
      }
    }
  }

  &.text-white-color {

    h1, h2, h3, h4, h5, h6 {
      color: var(--white) !important;
    }

    p,
    li,
    td {
      color: var(--white) !important;

      a {
        color: var(--turquoise) !important;

        &:hover {
          color: var(--white) !important;
        }
      }
    }

    ul {
      li {

        &:before {
          background: var(--white);
        }
      }
    }

    ol {

      li {

        &:before {
          color: var(--white);
        }
      }
    }
  }
}

