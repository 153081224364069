.first-interior-section {
    &.about-us-page {
        &.technology-page {
            padding-top: ac(214px, 140px);
            padding-bottom: ac(125px, 70px);
            .title {
                h1 {
                    line-height: 121.43%;
                }
            }

            .logo-decor {
                right: ac(98px, 0px);
            }
        }
    }
}

.technology-section {
    padding-top: ac(100px, 70px);
    padding-bottom: ac(304px, 190px);
    position: relative;


    .technology-list {
        margin: 0 auto;
        max-width: 1114px;
        width: 88%;
        position: relative;
        z-index: 1;
        transform: translateX(ac(14px, 0px));

        .technology-card {
            width: 100%;
            transition: transform .3s ease, opacity .25s ease;
            opacity: 0;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &:nth-child(odd) {
                transform: translateX(25%) translateY(15%);
                &.animation {
                    transform: translateX(0) translateY(0);
                    opacity: 1;
                }
            }

            &:nth-child(even) {
                transform: translateX(-25%) translateY(15%);
                &.animation {
                    transform: translateX(0) translateY(0);
                    opacity: 1;
                }
            }
        }

        @mixin tab {
            width: 100%;
        }
    }

    .technology-section-bg {
        left: 0;
        right: 0;
        bottom: 0;
        height: ac(755px, 400px);
        background: var(--gradient-main);
        position: absolute;
        overflow: hidden;
        clip-path: inset(0 0 0 0);

        .lottie-animation {
            bottom: -2%;
            left: -50%;
            opacity: .22;
            position: absolute;
            transform: rotate(-35deg) scaleX(1.4);
            width: 120%;
        }

        .decor-lines {
            @mixin aspect-ratio 2153, 1329;
            position: absolute;
            right: 31px;
            top: 44px;
            width: 149.51vw;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: right;
            }
        }
    }
}